<template>
  <modal
      @close="$emit('close')"
      class="choose-express-order-modal choose-express-order"
  >
    <template slot="header">
      <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['consolidations_whatOrderNeed'])"></span>
        {{$t('consolidations_whatOrderNeed.localization_value.value')}}
      </span>
    </template>
    <template slot="body">

      <div class="what-order-create__list custom-row">
        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['consolidations_USA',])"></div>
          <div class="what-order-create__link flex-column"
               @click="$emit('checkOrdersForConsolidationType', ORDERS_CONSOLIDATION_TYPE.FEDEX)"
          >
            <div class="what-order-create__name">
              {{ $t('consolidations_USA.localization_value.value') }}
            </div>
          </div>
        </div>


        <template v-if="!(isUkrainian || isUserAlternativeContactUkraine)">
          <div v-if="(isUkrainian || isAdmin)" class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['consolidations_USADHL',])"></div>
            <div class="what-order-create__link flex-column"
                 @click="$emit('checkOrdersForConsolidationType', ORDERS_CONSOLIDATION_TYPE.DHL_USA)"
            >
              <div class="what-order-create__name">
                {{ $t('consolidations_USADHL.localization_value.value') }}
              </div>
            </div>
          </div>
        </template>

        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['consolidations_Europe',])"></div>
          <div class="what-order-create__link flex-column"
               @click="$emit('checkOrdersForConsolidationType', ORDERS_CONSOLIDATION_TYPE.DPD)"
          >
            <div class="what-order-create__name">
              {{ $t('consolidations_Europe.localization_value.value') }}
            </div>
          </div>

        </div>

        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['consolidations_yourCity',])"></div>
          <div class="what-order-create__link flex-column"
               @click="$emit('checkOrdersForConsolidationType', ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY)"
          >
            <div class="what-order-create__name">
              {{ $t('consolidations_yourCity.localization_value.value') }}
            </div>
          </div>

        </div>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  // import OrderTypeCardBig from "../../../../coreComponents/OrderTypeCardBig/OrderTypeCardBig";
  import {ORDERS_CONSOLIDATION_TYPE} from "../../../../../staticData/staticVariables";

  export default {
    name: "ChooseConsolidationTypePopup",
    components: {
      Modal,
      // OrderTypeCardBig,
    },

    data(){
      return {
        ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
      }
    }
  }

</script>

<style scoped>
.what-order-create__link {
  cursor: pointer;
}
.choose-express-order__item {
  padding: 0 5px;
}
</style>
